/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
// @import "~bootstrap-icons/font/bootstrap-icons";

@font-face {
  font-family: Roboto;

  src:
    url("/assets/fonts/hurme-geometric-sans-3.woff2") format("woff2"),
    url("/assets/fonts/hurme-geometric-sans-3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
.h-100 {
  height: 100%;
}

.w-auto {
  width: auto;
}
.w-fit {
  width: fit-content;
}
.column-gap-lg {
  column-gap: 3rem;
}
.column-gap-md {
  column-gap: 2.5rem;
}
.column-gap-s {
  column-gap: 2rem;
}
.column-gap-xs {
  column-gap: 1.56rem;
}
.column-gap-2xs {
  column-gap: 0.5rem;
}

.container-fluid.boxed {
  max-width: 1240px !important;
  padding: 0 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bx-trash:hover {
  color: #f46a6a;
}

@media (max-width: 767px) {
  .card-img.img-fluid {
    border-radius: 15px 15px 0 0px;
  }
}
.mx-height-180 {
  max-height: 191px;
}
.text-h-underline {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.text-hover-warning {
  &:hover {
    color: $yellow-500 !important;
  }
}
.text-black {
  color: rgb(20, 20, 20) !important;
}
.checkbox-dayim {
  -webkit-appearance: none;
  position: relative;
  background: #f8f7f7;
  border-color: #000000 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 25px !important;
  width: 25px !important;
  &:checked {
    background: linear-gradient(298deg, #ffc400, #ff8920) !important;
    &::before {
      display: block;
    }
  }
  &:focus {
    box-shadow: 0px 0px 6px 3px #ffb20d55 !important;
  }
  &::before {
    content: "\ea41";
    font-family: "boxicons" !important;
    color: #fff;
    border-color: unset !important;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: x-large;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}
.btn-dayim {
  background-color: #ff672d !important;
  border-color: #ff672d !important;
  color: #000;
  &:hover {
    background-color: #ff672d !important;
    border-color: #ff672d !important;
  }
  border-radius: 10px;
}

.text-underline {
  text-decoration: underline;
}

.required-asterisk {
  color: red;
  font-size: 1.2em;
}
ngx-intl-tel-input input {
  height: 36px;
  margin-bottom: 20px;
  padding: 8px;
  border-style: solid;
  // border-width: 1px;
  // border-color: #d71386;
  // border-radius: 4px;
  // width: 216px !important;
  // font-size: 0.8125rem;

  display: inline-flex;
  height: 46px;
  width: 375px !important;

  // max-width: 44px;
  // padding: 14.667px;
  // padding-right: 14.667px;
  // padding-left: 14.667px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1.333px solid rgba(0, 0, 0, 0.5);
}

ngx-intl-tel-input.is-invalid input {
  border: 1px solid #f46a6a;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
  // width: 100px;
}

.cc-window.cc-banner.cc-bottom {
  width: 75%;
  margin: 20px auto;
  padding: 11px 13px;
  border-radius: 14px;
  box-shadow: 0px 0px 10px 6px #0000001d;
}

.w-33 {
  width: 33.33%;
}
.w-66 {
  width: 66.66%;
}
.text-dayim {
  background: -webkit-linear-gradient(298deg, #ffc400, #ff8920);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff00;
}
.text-h-dayim {
  &:hover {
    background: -webkit-linear-gradient(298deg, #ffc400, #ff8920);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff00;
  }
}
i.bxs-heart.fill {
  background: linear-gradient(167.95deg, #ffb30d 0%, #ff672d 76.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mt-6 {
  margin-top: 4.3rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
button {
  &[type="submit"] {
    border-radius: 10px;
  }
  &:disabled {
    color: #000 !important;
    background-color: rgb(190, 190, 190) !important;
  }
}
html[dir="rtl"] {
  .bx-left-arrow-alt::before {
    content: "\ebe6" !important;
  }
  .ngb-datepicker-navigation-select > .form-select {
    padding-inline-start: 30px;
  }
  .card-img.img-fluid {
    border-radius: 0px 15px 15px 0px;
  }
  .section-one {
    .section-two {
      right: 65%;
      left: 0;
    }
  }
  .category-tree summary::before {
    content: "\ed38" !important;
    left: unset;
    right: calc(var(--spacing) - var(--radius) - 1px) !important;
  }
}
.bg-dayim.bg-soft {
  background: linear-gradient(298deg, #ffc4004f, #ff89204f);
  color: #000;
}
.btn-dayim:disabled {
  border: transparent;
}

.bg-dayim {
  background: linear-gradient(298deg, #ff672d, #ff672d);
  color: white;
}

.owl-prev,
.owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  border: 0px solid black;
}
.owl-prev {
  left: -26px;
}
.owl-next {
  right: -16px;
}
.owl-prev i,
.owl-next i {
  transform: scale(2, 5);
  color: #000000;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #ffffff;
  background: none;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none;
  color: #ffffff;
  text-decoration: none;
}
.carousel-products {
  .owl-carousel .owl-item {
    width: 250px !important;
  }
}
.b-shadow-none {
  box-shadow: none;
}
.dayim-switch:checked {
  background-color: #ffc107 !important;
  border: #ffc107 !important;
}

// ----------------------------------------------           VERSION - 2    ---------------------------------------------------------
// *********************************************************************************************************************************

// NEW GLOBAL CODE
ng-select.is-invalid .ng-select-container {
  border-color: #f46a6a !important;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

/* Style for the ng-select container */
.ng-select {
  width: 100%;
  font-size: 16px;
}

/* Style for the ng-select dropdown list */
.ng-dropdown-panel {
  background-color: #d73434; /* Background color for the dropdown */
  max-height: 200px; /* Set a max height for the dropdown list; you can adjust this value */
  overflow-y: hidden !important;
}

/* Style for the ng-select option items */
.ng-option {
  padding: 8px 12px;
  cursor: pointer;
}

/* Style for the selected option */
.ng-value {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.ng-select .ng-placeholder {
  color: #000000 !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: left;
}

// .ng-select .ng-select-container {
//   color: #333;
//   background-color: #b41515;
//   border-radius: 4px;

//   min-height: 36px;
//   align-items: center;
// }

.ng-select .ng-select-container {
  color: #333;
  background-color: #b41515;
  border-radius: 4px;
  border: 1px solid #0c0c0c; /* Add this line for the border */

  min-height: 36px;
  align-items: center;
}

/* Style the clearable "X" icon */
// .ng-clear-wrapper::before {
//   content: "X";
//   color: #fd1313;
//   font-size: 16px;
//   cursor: pointer;
// }

// BUTTONS - start
.close-btn {
  display: flex;
  height: 40px;
  padding: 9.471px 20.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #ff672d;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.accept-btn {
  display: flex;
  height: 50px;
  padding: 9.471px 20.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  background: var(--Equipment-Orange, #ff672d);
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.reject-btn {
  display: flex;
  height: 50px;
  padding: 9.471px 20.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #ff672d;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.submit-btn {
  display: flex;
  height: 40px;
  padding: 9.471px 20.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  background: var(--Equipment-Orange, #ff672d);
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.list-btn {
  display: flex;
  height: 40px;

  padding: 9.471px 50.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  background: var(--Equipment-Orange, #fff);
  border: 1px solid #ff672d;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #ff672d;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.back-home-btn {
  display: flex;
  height: 40px;
  padding: 9.471px 30.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  background: var(--Equipment-Orange, #ff672d);
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.btn-blur-listing {
  // color: #000;
  display: flex;
  height: 40px;
  padding: 20.471px 24.663px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  border-radius: 6.888px;
  background: var(--Equipment-Orange, #ff672d);
  //width: 200px;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.clear-all-btn {
  height: 25px;
  flex-shrink: 0;
  color: #000;
  text-align: right;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  opacity: 0.7;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }
}

.rent-btn {
  display: inline-flex;
  padding: 11px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Equipment-Orange, #ff672d);
  margin-left: 40px;
  margin-top: 40px;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */

  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }

  div {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 425px) {
    margin-left: 0px; /* Adjusted margin-left for screens smaller than 425px */
    margin-top: 15px; /* Adjusted margin-top for screens smaller than 425px */
    padding: 5px 13px; /* Adjusted padding for screens smaller than 425px */

    div {
      font-size: 13px; /* Adjusted font size for screens smaller than 425px */
    }
  }
}

.rent-btn:hover {
  background: var(--Equipment-Orange-Hover, #ff4500); /* Change the background color on hover */
}

.free-btn {
  display: inline-flex;
  padding: 11px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  margin-left: 40px;
  margin-top: 40px;
  border: 1px solid #fff;
  transition: transform 0.3s; /* Add a smooth transition for the transform property */
  &:active {
    transform: scale(0.9); /* Scale down to 90% on click */
  }
  div {
    color: var(--Equipment-Orange, #ff672d);
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
  }
}

.free-btn:hover {
  border: 1px solid var(--Equipment-Orange-Hover, #ff4500);
}

.view-profile {
  border-radius: 8.294px;
  background: #ff672d;
  width: 108.168px;
  height: 37.472px;
  flex-shrink: 0;
}

// BUTTONS - end

// HEADERS - start
.sub-header {
  color: #000;
  padding-left: 25px;

  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.header {
  color: #000;
  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 0px;
}

.data-not-available {
  color: #777; /* Grey color */
  font-weight: 500; /* Slightly bolder text */
  font-size: 16px;
}

.page-main-header {
  color: #000;
  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 0 20px 0;
}

.white-label-large {
  color: #fff;
  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 0;
}

.white-label-mid {
  color: #fff;
  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 0;
}

.white-label-sm {
  color: #fff;
  font-family: "Hurme Geometric Sans 3", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 0;
}

.black-light-lg {
  color: #495057;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.518px;
}

.black-light-mid {
  color: #495057;
  font-family: "Roboto", sans-serif;
  font-size: 16.589px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.518px;
}
.black-light-sm {
  color: #8d9297;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.518px;
}

// HEADERS - end

// INPUT FIELD CUSTOM - start
.address-text-area {
  border: 1px solid rgba(0, 0, 0, 0.5);
  // display: block;
  width: 100%;
  height: 75px;
  flex-shrink: 0;
  padding: 15px 0px 14px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bs-custom-white);
  background-clip: padding-box;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.message-text-area {
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  padding: 15px 0px 14px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bs-custom-white);
  background-clip: padding-box;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.message-text-area::placeholder {
  color: #000 !important;
  text-align: left !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
// INPUT FIELD CUSTOM - end

/* ABOUT-US - START */

.line-about-us {
  width: 100px;
  height: 3px;

  background-color: #ff672d !important;
  border-radius: 10px;
}

.line-about-us2 {
  padding-bottom: 0px;
  width: 200px;
  height: 1px;

  background-color: #ff672d !important;
  border-radius: 10px;
}
/* Default styles for larger screens */
.main-label {
  color: #000;
  font-family: "Hurme Geometric Sans 3";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* or "150%" */
}

.main-sub-label {
  color: #000;
  font-family: "Hurme Geometric Sans 3";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* or "112.5%" */
  text-transform: uppercase;
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
  .main-label {
    font-size: 18px; /* Adjust the size as needed for smaller screens */
    line-height: 22px; /* Adjust the line height accordingly */
    /* Add other adjustments as needed */
  }

  .main-sub-label {
    font-size: 20px; /* Adjust the size as needed for smaller screens */
    line-height: 20px; /* Adjust the line height accordingly */
    /* Add other adjustments as needed */
  }
}

/* ABOUT-US - END */

/* MY ACCOUNTS - START */
.account-label {
  color: #999;

  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.account-data {
  color: #000;

  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.btn-change {
  display: inline-flex;
  height: 23.721px;
  padding: 9.471px 12px;
  justify-content: center;
  align-items: center;
  gap: 8.61px;
  flex-shrink: 0;
  border-radius: 6.888px;
  background: #ffe3d9;
  color: #ff672d;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* MY ACCOUNTS - END */

.head-navbar {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  list-style: none;
}

//----------------------- COMMENTED CODE  - START-----------------------------

// inquiries page

// .custom-nav-item {
//   width: 10px !important;
//   position: relative;
//   color: var(--#{$prefix}dark);
//   .nav-link {
//     border: none;

//     &::after {
//       content: "";
//       background: rgba(255, 103, 45, 1);
//       height: 2px;
//       position: absolute;
//       width: 100%;
//       left: 0;
//       bottom: -1px;
//       transition: all 250ms ease 0s;
//       transform: scale(0);
//     }

//     &.active{
//       color: var(--#{$prefix}dark);
//       &:after{
//         transform: scale(1);
//       }
//     }

//   }
// }

// .custom-nav-pills {
//   > a {
//       // color: var(--#{$prefix}gray-700);
//       // font-weight: $fw-medium;
//   }
// }

//----------------------- COMMENTED CODE  - END-----------------------------

$desktop: 1200px; // col-xl
$laptop: 1024px; // lg
$tablet: 768px; // md
$mobile: 480px; // sm

body {
  // font-family: "Hurme Geometric Sans 3" !important;

  // @media screen and (max-width: desktop) and (min-width: tablet) {
  //   background-color: red !important;
  // }
}

// body {
//   @media (max-width:$mobile) {
//     display: block;
//     font-size: 25px;
//     width: 100% !important
//   }
// }

body {
  font-family: "Hurme Geometric Sans 3" !important;

  width: 100% !important;
}

.data-not-available {
  color: #777; /* Grey color */
  font-weight: 500; /* Slightly bolder text */
  font-size: 16px;
}

//Css for no msg and loader
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid;
  position: absolute;
  left: 400px;
  top: 150px;
  border-color: #ff3d00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loaderreview {
  width: 48px;
  height: 48px;
  border: 5px solid;
  position: absolute;
  left: 300px;
  top: 450px;
  border-color: #ff3d00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-chats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
}

.no-chats-image {
  height: 200px;
  left: 300px;
  position: absolute;
}

//Css for no msg and loader ends
