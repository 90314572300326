/*
Template Name: Dayim RentalShare 
Author: KPI
Version: 4.0.0.
Website: https://kpi.co/
Contact: admin@kpi.co
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";