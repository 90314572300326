// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  border-radius: 18px;
  box-shadow: 0 0rem 1.5rem rgb(18 38 63 / 12%)
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}